<template>
  <ui-component-modal
    modalTitle=""
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveMailText"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="pb-3">
        <input type="text" class="input" v-model="mMailText.Subject" />
      </div>

      <div class="field">
        <div class="control">
          <ui-text-editor
            :id="'editor'"
            v-model="mMailText.Body"
          ></ui-text-editor>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { EventBus } from '@/eventbus/event-bus'
import mailProvider from '@/providers/mail'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    mailText: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      mMailText: null,
    }
  },

  computed: {},

  created() {
    this.mMailText = JSON.parse(JSON.stringify(this.mailText))
  },

  methods: {
    saveMailText() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        mailProvider.methods
          .saveMailText(this.mMailText)
          .then((response) => {
            if (response.status === 200) {
              this.$emit('mailTextUpdated', response.data)

              EventBus.$emit('showToast', {
                type: 'info',
                message: 'Text saved!',
              })

              self.onClickCancel()
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
